( function ( $, cookieconsent ) {
    "use strict";

    function showCookieConsentMessage ( config ) {

        window.addEventListener( "load", function () {

            cookieconsent.initialise( {
                onPopupOpen: function() {
                    $( ".cc-window" ).addClass( config.buttonStyle + " " + config.fontSize )
                },
                theme: config.theme,
                position: config.overlayPosition === "top-pushdown" ? "top" : config.overlayPosition,
                static: config.overlayPosition === "top-pushdown",
                content: {
                    message: config.message,
                    dismiss: config.dismissButtonLabel,
                    link: config.infoLinkLabel,
                    href: config.infoLinkUrl
                },
                showLink: config.showInfoLink,
                location: config.showInRelevantCountries && { timeout: [3000, 3000], services: ["ipinfo", "maxmind"] }
            } )

        } );

    }

    /**
     * Export a global function
     */
    window.showCookieConsentMessage = showCookieConsentMessage;

})( jQuery, cookieconsent );